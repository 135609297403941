<template>
  <div>
    <b-row>
      <b-col lg="3" md="12" xl="3" class="order-lg-2 order-xl-2">
        <KTPortlet>
          <template v-if="isBusy" v-slot:body>
            <div class="text-center text-danger my-2">
              <b-spinner variant="danger"></b-spinner><br />
              <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
            </div>
          </template>
          <template v-else v-slot:body>
            <b-row>
              <b-col>
                <b-img
                  v-if="isPhotoLoaded"
                  v-bind="imageSize"
                  rounded
                  :alt="lecturer_profile.name"
                  :src="userPhoto"
                ></b-img>
              </b-col>
              <b-col class="font-weight-bold kt-padding-t-40">
                <h5>
                  <span class="mt-0">{{ lecturer_profile.name + " " }}</span>
                  <span class="mt-0" v-if="lecturer_profile.last_name != null">
                    {{ lecturer_profile.last_name }}
                  </span>
                </h5>
              </b-col>
            </b-row>
            <br />
            <b-row>
              <b-col>
                <label>{{ $t("PROFILE.ID") }}:</label>
              </b-col>
              <b-col>
                <span>{{ lecturer_profile.lecturer_id }}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label>{{ $t("PROFILE.CONTRACT_TYPE") }}:</label>
              </b-col>
              <b-col>
                <span>{{ contract_type }}</span>
              </b-col>
            </b-row>
            <br />
            <b-row>
              <b-col>
                <router-link to="/reset-password">
                  <b-button block variant="success">
                    <i class="flaticon-lock"></i>
                    {{ $t("AUTH.FORGOT.RESET_PASSWORD") }}
                  </b-button>
                </router-link>
              </b-col>
            </b-row>
          </template>
        </KTPortlet>
      </b-col>
      <b-col lg="9" md="12" xl="9" class="order-lg-2 order-xl-2">
        <KTPortlet :title="title">
          <template v-if="isBusy" v-slot:body>
            <div class="text-center text-danger my-2">
              <b-spinner variant="danger"></b-spinner><br />
              <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
            </div>
          </template>
          <template v-else v-slot:body>
            <b-card
              border-variant="dark"
              :header="$t('PROFILE.PERSONAL_INFORMATION')"
              header-bg-variant="dark"
              header-text-variant="white"
              align="left"
            >
              <b-card-text>
                <b-row>
                  <b-col>
                    <label>{{ $t("PROFILE.NAME") }}:</label>
                  </b-col>
                  <b-col class="border-right border-dark">
                    <span>{{ lecturer_profile.name }}</span>
                  </b-col>
                  <b-col>
                    <label>{{ $t("PROFILE.LAST_NAME") }}:</label>
                  </b-col>
                  <b-col>
                    <span>{{ lecturer_profile.last_name }}</span>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <label>{{ $t("PROFILE.FATHER_NAME") }}:</label>
                  </b-col>
                  <b-col class="border-right border-dark">
                    <span>{{ lecturer_profile.father_name }}</span>
                  </b-col>
                  <b-col>
                    <label>{{ $t("PROFILE.GRAND_FATHER_NAME") }}:</label>
                  </b-col>
                  <b-col>
                    <span>{{ lecturer_profile.grand_father_name }}</span>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <label>{{ $t("PROFILE.MOTHER_TONGUE") }}:</label>
                  </b-col>
                  <b-col class="border-right border-dark">
                    <span>{{ native_languages }}</span>
                  </b-col>
                  <b-col>
                    <label>{{ $t("PROFILE.GENDER") }}:</label>
                  </b-col>
                  <b-col>
                    <span v-if="lecturer_profile.gender == 1">ذکور</span>
                    <span v-if="lecturer_profile.gender == 2">اناث</span>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <label>{{ $t("PROFILE.PLACE_OF_BIRTH") }}:</label>
                  </b-col>
                  <b-col class="border-right border-dark">
                    <span>{{ lecturer_profile.birth_place }}</span>
                  </b-col>
                  <b-col>
                    <label>{{ $t("PROFILE.DATE_OF_BIRTH") }}:</label>
                  </b-col>
                  <b-col>
                    <span>{{ lecturer_profile.date_of_birth }}</span>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <label>{{ $t("PROFILE.BLOOD_GROUP") }}:</label>
                  </b-col>
                  <b-col class="border-right border-dark">
                    <span>{{ lecturer_profile.blood_group }}</span>
                  </b-col>
                  <b-col>
                    <label>{{ $t("PROFILE.MARITAL_STATUS") }}:</label>
                  </b-col>
                  <b-col>
                    <span>{{ marital_status }}</span>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <label>{{ $t("PROFILE.CONTACT_NUMBER") }}:</label>
                  </b-col>
                  <b-col class="border-right border-dark">
                    <span>{{ lecturer_profile.contact_number }}</span>
                  </b-col>
                  <b-col>
                    <label>{{ $t("PROFILE.EMAIL_ADDRESS") }}:</label>
                  </b-col>
                  <b-col>
                    <span>{{ lecturer_profile.email_address }}</span>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <label>{{ $t("PROFILE.TAZKIRA_ID") }}:</label>
                  </b-col>
                  <b-col class="border-right border-dark">
                    <span>{{ lecturer_profile.tazkira_id }}</span>
                  </b-col>
                  <b-col>
                    <label>{{ $t("PROFILE.TAX_NUMBER") }}:</label>
                  </b-col>
                  <b-col>
                    <span>{{ lecturer_profile.tax_number }}</span>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <label>{{ $t("PROFILE.WORK_EXPERIENCE") }}:</label>
                  </b-col>
                  <b-col class="border-right border-dark">
                    <span>{{ lecturer_profile.work_experience }}</span>
                  </b-col>
                  <b-col>
                    <label>{{ $t("PROFILE.SCIENTIFIC_RANKING") }}:</label>
                  </b-col>
                  <b-col>
                    <span>{{ Lecturer_scientific_ranking }}</span>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
            <br />
            <b-card
              border-variant="dark"
              :header="$t('PROFILE.ACCOMMODATION_INFORMATION')"
              header-bg-variant="dark"
              header-text-variant="white"
              align="left"
            >
              <b-card-text>
                <b-row>
                  <b-col>
                    <b-alert show variant="dark"
                      >{{ $t("PROFILE.PERMANENT_RESIDENCE") }}:</b-alert
                    >
                  </b-col>
                  <b-col>
                    <b-alert show variant="dark"
                      >{{ $t("PROFILE.CURRENT_RESIDENCE") }}:</b-alert
                    >
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <label>{{ $t("PROFILE.RESIDENTIAL_PROVINCE") }}:</label>
                  </b-col>
                  <b-col class="border-right border-dark">
                    <span>{{ lecturer_profile.permanent_province }}</span>
                  </b-col>
                  <b-col>
                    <label>{{ $t("PROFILE.RESIDENTIAL_PROVINCE") }}:</label>
                  </b-col>
                  <b-col>
                    <span>{{ lecturer_profile.current_province }}</span>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <label>{{ $t("PROFILE.RESIDENTIAL_DISTRICT") }}:</label>
                  </b-col>
                  <b-col class="border-right border-dark">
                    <span>{{
                      lecturer_profile.permanent_province_district
                    }}</span>
                  </b-col>
                  <b-col>
                    <label>{{ $t("PROFILE.RESIDENTIAL_DISTRICT") }}:</label>
                  </b-col>
                  <b-col>
                    <span>{{
                      lecturer_profile.current_province_district
                    }}</span>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <label>{{ $t("PROFILE.RESIDENTIAL_VILLAGE") }}:</label>
                  </b-col>
                  <b-col class="border-right border-dark">
                    <span>{{
                      lecturer_profile.permanent_residential_village
                    }}</span>
                  </b-col>
                  <b-col>
                    <label>{{ $t("PROFILE.RESIDENTIAL_VILLAGE") }}:</label>
                  </b-col>
                  <b-col>
                    <span>{{
                      lecturer_profile.current_residential_village
                    }}</span>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
          </template>
        </KTPortlet>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" md="12" xl="12" class="order-lg-1 order-xl-1">
        <KTPortlet :title="$t('PROFILE.ACADEMIC_INFORMATION')">
          <template v-slot:body>
            <b-table
              :items="lecturer_education"
              :fields="fields"
              :busy="isBusy"
              striped
              hover
              show-empty
              head-variant="dark"
            >
              <template v-slot:empty="scope">
                <p class="text-center">
                  {{ $t("PROFILE.NO_DATA") }}
                </p>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
                </div>
              </template>
            </b-table>
          </template>
        </KTPortlet>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ApiService from "@/common/api.service";
import { getUserPhoto } from "@/common/jwt.service";
import { mapState } from "vuex";

export default {
  data() {
    return {
      isBusy: true,
      title: this.$t("PROFILE.GENERAL_INFORMATION"),
      fields: [
        { education_type: this.$t("PROFILE.EDUCATION_TYPE") },
        { institution: this.$t("PROFILE.INSTITUTION_NAME") },
        { field_of_study: this.$t("PROFILE.INSTITUTION_DEPARTMENT") },
        { graduation_year: this.$t("PROFILE.INSTITUTION_GRADUATION_YEAR") },
        { scores_average: this.$t("PROFILE.INSTITUTION_MARKS_AVERAGE") },
        { location: this.$t("PROFILE.INSTITUTION_ADDRESS") }
      ],
      lecturer_profile: [],
      lecturer_education: [],
      native_languages: [],
      marital_status: null,
      Lecturer_scientific_ranking: null,
      contract_type: "",
      imageSize: { width: 100, height: 100 }
    };
  },
  components: {
    KTPortlet
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.PROFILE"),
        route: this.$route
      },
      { title: "" }
    ]);
    ApiService.get("lecturer/profile").then(({ data }) => {
      this.lecturer_profile = data.lecturer_profile;
      this.lecturer_education = data.lecturer_education;
      this.contract_type =
        this.lecturer_profile.contract_type_id === 1
          ? this.$t("PROFILE.PERMANENT")
          : this.$t("PROFILE.CONTRACTUAL");
      if (this.$i18n.locale === "fa") {
        this.native_languages = data.native_languages["0"]["local_name"];
      } else {
        this.native_languages = data.native_languages["0"]["english_name"];
      }

      if (this.$i18n.locale === "fa") {
        this.Lecturer_scientific_ranking =
          data.Lecturer_scientific_ranking["0"]["local_name"];
      } else {
        this.Lecturer_scientific_ranking =
          data.Lecturer_scientific_ranking["0"]["english_name"];
      }

      this.marital_status =
        this.lecturer_profile.marital_status === 1
          ? this.$t("PROFILE.MARRIED")
          : this.$t("PROFILE.SINGLE");
      this.isBusy = false;
    });
  },
  computed: {
    ...mapState({
      isPhotoLoaded: state => state.auth.isPhotoLoaded
    }),
    userPhoto() {
      if (this.isPhotoLoaded) {
        return getUserPhoto();
      }
      return null;
    }
  }
};
</script>
